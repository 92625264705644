import preguntas from "./resources/preguntas"
import {partidos, descripciones} from "./resources/partidos"
import _ from 'lodash'
import {useState} from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTwitter, faFacebook, faLinkedin} from "@fortawesome/free-brands-svg-icons";


function App() {

  const nPreguntas = 10;

  const [ pregunta, setPregunta ] = useState(_.sample(preguntas))
  const [ partido, setPartido ] = useState(_.sample(partidos))
  const [ preguntasVisitadas, setPreguntasVisitadas ] = useState([pregunta['id']])
  const [ mostrarIniciar, setMostrarIniciar ] = useState(true)
  const [ mostrarSpinner, setMostrarSpinner ] = useState(false)
  const [ descripcion, setDescripcion ] = useState('')

  const iniciar = () => {
    setMostrarIniciar(false)
  }

  const cambiaPregunta = () => {
    let pregunta = _.sample(preguntas)
    if(preguntasVisitadas.length < preguntas.length) {
      while(preguntasVisitadas.includes(pregunta['id'])) {
        pregunta = _.sample(preguntas)
      }
      setPregunta(pregunta)
      setPreguntasVisitadas(preguntasVisitadas.concat([pregunta['id']]))
    }
    if(preguntasVisitadas.length === nPreguntas) {
      setDescripcion(_.sample(descripciones).replace('PARTIDO', partido['nombre_largo']))
      muestraSpinner()
    }
  }

  const reinicia = () => {
    let pregunta = _.sample(preguntas)
    setPregunta(pregunta)
    setPreguntasVisitadas([pregunta['id']])
    setPartido(_.sample(partidos))
    setMostrarSpinner(false)
  }

  const muestraSpinner = () => {
    setMostrarSpinner(true)
    setTimeout(()=> {
      setMostrarSpinner(false)
    }, 4000)
  }

  return (
    <>
      {
        (mostrarIniciar) && (
          <div className='text-center row'>
            <div className='col'>
              <button className='m-5 p-3 pl-5 pr-5 font-weight-bold h2' onClick={ iniciar }>
                Empezar
              </button>
            </div>
          </div>
        )
      }
      {
        (!mostrarIniciar && preguntasVisitadas.length <= nPreguntas) && (
          <div className='text-center row'>
            <div className='col'>
              <h1 className='mb-5 font-weight-bold'>{ pregunta['titulo'] }</h1>
              <ul>
                {
                  (_.shuffle(pregunta['opciones']).map((opcion) => (
                    <li className='mb-3 p-2 font-weight-bold h4' key={opcion} onClick={ cambiaPregunta }>{opcion}</li>
                  )))
                }
              </ul>
            </div>
          </div>
        )
      }
      {
        (!mostrarIniciar && preguntasVisitadas.length > nPreguntas && mostrarSpinner) && (
          <div className='text-center row'>
            <div className='col'>
              <Spinner animation="border" variant="light" />
              <p className='h4 mt-5'>
                Inteligencia Artificial optimizando su voto...
              </p>
            </div>
          </div>
        )
      }
      {
        (!mostrarIniciar && preguntasVisitadas.length > nPreguntas && !mostrarSpinner) && (
          <>
            <div className='text-center row'>
              <div className='col'>
                <img className='mb-5' src={ process.env.PUBLIC_URL + "/" + partido['logo']} alt='logo'/>
                <p className='h4'>
                  { descripcion }
                </p>
                <button className='m-5 p-3 pl-5 pr-5 font-weight-bold h4' onClick={ reinicia }>
                  Volver a hacer el test
                </button>
              </div>
            </div>
            <div id='row' className='text-center row'>
              <div id='icons' className='col'>
                <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.homovelamine.com%2Fvotomaton%2F">
                  <FontAwesomeIcon className='mr-3' icon={faFacebook} size={"3x"} />
                </a>
                <a target='_blank' href={"https://twitter.com/intent/tweet?url=https://www.homovelamine.com/votomaton/&text=Ya%20s%C3%A9%20a%20qui%C3%A9n%20tengo%20que%20votar%20en%20Madrid%20gracias%20al%20VOTOMAT%C3%93N%3A%0A%0A" + encodeURIComponent(descripcion) + "%0A%0A" }>
                  <FontAwesomeIcon className='mr-3' icon={faTwitter} size={"3x"} />
                </a>
                <a target='_blank' href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.homovelamine.com%2Fvotomaton%2F">
                  <FontAwesomeIcon icon={faLinkedin} size={"3x"} />
                </a>
              </div>
            </div>
          </>
        )
      }
    </>
  )
}

export default App;
