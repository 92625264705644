export const partidos = [
  {
    "nombre": "PP",
    "logo": "PP.svg",
    "nombre_largo": "el Partido Popular"
  },
  {
    "nombre": "PSOE",
    "logo": "PSOE.svg",
    "nombre_largo": "el Partido Socialista Obrero Español",
  },
  {
    "nombre": "UP",
    "logo": "UP.svg",
    "nombre_largo": "Unidas Podemos",
  },
  {
    "nombre": "Cs",
    "logo": "CS.png",
    "nombre_largo": "Ciudadanos",
  },
  {
    "nombre": "MM",
    "logo": "MM.png",
    "nombre_largo": "Más Madrid",
  },
  {
    "nombre": "Vox",
    "logo": "VOX.svg",
    "nombre_largo": "Vox",
  },
]

export const descripciones = [
  "Eres amigo/a de tus amigos/as y tu sonrisa no deja indiferente a nadie. Tu partido es PARTIDO.",
  "Pese a que los demás te hacen sentir raro/a, tú sabes que eres especial como un copo de nieve. Tu partido es PARTIDO.",
  "Tu espíritu libre te pide ensanchar las autopistas de la vida. Tu partido es PARTIDO.",
  "Aunque te llamen cabezota, sigue luchando por tus sueños, la insistencia tiene premio. Tu partido es PARTIDO.",
  "Los momentos que pasas con tus seres queridos no los cambiarías por nada. Tu partido es PARTIDO.",
  "Los atardeceres en lugares idílicos son tu pasión. Estás lleno/a de vida. Tu partido es PARTIDO.",
  "Por fuera puedes parecer frío/a y distante, pero los que te conocen saben que eres un cacho de pan. Tu partido es PARTIDO.",
  "No te importa dar más de lo que recibes, porque sabes que la solidaridad es vida. Tu partido es PARTIDO.",
  "Tu padre te enseñó una lección muy importante: en el amor y en la guerra NO todo está permitido. Tu partido es PARTIDO.",
  "No lo pienses más y tómate de una vez esas vacaciones que llevas tanto tiempo postergando. Tu sonrisa lo merece. Tu partido es PARTIDO.",
  "Eres honesto/a y trabajador/a. El mundo sería un lugar mejor si todos fuéramos como tú. Tu partido es PARTIDO",
  "Puedes ganar cualquier batalla siempre y cuando utilices tus propias armas: la sinceridad y el respeto. Tu partido es PARTIDO"
]
