const preguntas = [
  {
    "id": 1,
    "titulo": "¿Cuál es tu color de ojos?",
    "opciones": [
      "Verdes", "Azules", "Marrones", "Negros", "Grises", "Rojos"
    ]
  },
  {
    "id": 2,
    "titulo": "¿Qué número de pie calzas?",
    "opciones": [
      "Menos de 35", "Entre 35 y 38", "Entre 38 y 40", "Entre 40 y 42", "Entre 42 y 45", "Más de 45"
    ]
  },
  {
    "id": 3,
    "titulo": "¿Cuál es tu signo del zodiaco?",
    "opciones": [
      "Aries", "Tauro", "Geminis", "Cáncer", "Leo", "Virgo", "Libra", "Escorpio", "Sagitario",
      "Capricornio", "Acuario", "Piscis"
    ]
  },
  {
    "id": 4,
    "titulo": "¿Cuál es la primera letra de tu nombre?",
    "opciones": [
      "Y", "Ñ", "X", "K", "W", "Otra"
    ]
  },
  { "id": 5,
    "titulo": "¿Fumas?",
    "opciones": [
      "Solo rubio", "Sí, en pipa", "No", "Sí", "Solo negro", "No, pero vapeo"
    ]
  },
  { "id": 6,
    "titulo": "¿Cada cuántos días te sueles duchar?",
    "opciones": [
      "Me ducho todos los días", "Me ducho más de una vez al día", "Cada dos días", "Cada tres días", "Cada cuatro días", "Cada cinco días o más"
    ]
  },
  {
    "id": 7,
    "titulo": "¿Has leido \"El útero forzudo del precariado\" de James Doppelganger?",
    "opciones": [
      "Sí, es mi biblia", "No", "Sí, y ojalá no lo hubiera hecho", "No, pero estoy deseando leerlo",
      "No sé quién es el Dopelgeta ese", "Solo leo clásicos"
    ]
  },
  {
    "id": 8,
    "titulo": "¿Cuál es tu longitud de pelo?",
    "opciones": [
      "Rapado al cero", "Por las orejas", "Por la nuca", "Corto", "Largo", "Muy largo"
    ]
  },
  {
    "id": 9,
    "titulo": "¿Usas gafas?",
    "opciones": [
      "Solo de sol", "No, pero uso lentillas", "Sí, de culo de vaso", "Sí, de pasta", "No",
      "No, pero debería usarlas para conducir"
    ]
  },
  {
    "id": 10,
    "titulo": "¿Tienes pareja?",
    "opciones": [
      "Sí", "Sí, estoy casado/a", "No por principios",
      "No", "No, y nunca he tenido", "Tengo varias, soy poliamoroso/a"
    ]
  },
  {
    "id": 11,
    "titulo": "¿Cuál es la marca de tu lavadora?",
    "opciones": [
      "Bosch", "Fagor", "Whirpool", "Teka", "AEG", "Otra"
    ]
  },
  {
    "id": 12,
    "titulo": "¿Cuál es tu supermercado habitual?",
    "opciones": [
      "Carrefour", "Lidl", "Supercor", "Día%", "Sánchez Romero", "Otro"
    ]
  },
  {
    "id": 13,
    "titulo": "¿Te gustan los garbanzos?",
    "opciones": [
      "Sí, con chorizo", "Sí, con espinacas", "No",
      "Sí, pero no me sientan bien", "Sí", "Solo en cocido, como buen madrileño"
    ]
  },
  {
    "id": 14,
    "titulo": "¿Tienes perro?",
    "opciones": [
      "Sí", "No"
    ]
  },
  {
    "id": 15,
    "titulo": "¿Tienes gato?",
    "opciones": [
      "Sí", "No"
    ]
  },
  {
    "id": 16,
    "titulo": "¿Cuándo fue la última vez que viste amanecer?",
    "opciones": [
      "Nunca he visto amanecer", "Cada mañana cuando salgo a trabajar", "El fin de semana",
      "Lo veo todas las mañanas incluyendo fines de semana", "No me acuerdo", "En mis últimas vacaciones"
    ]
  },
  {
    "id": 17,
    "titulo": "¿Has visto la película \"El código Da Vinci\"?",
    "opciones": [
      "Sí", "No, pero me la han contado",
      "No", "Sí, pero prefiero el libro", "No, pero he leído el libro",
      "No, y no pienso verla"
    ]
  },
  {
    "id": 18,
    "titulo": "¿Cuándo fue la última vez que comiste langostinos?",
    "opciones": [
      "Estoy comiéndome unos ahora", "Hace un minuto", "Hace dos minutos",
      "Hace más de dos minutos", "Nunca he comido langostinos",
      "Antes de que me hiciera vegetariano/a/vegano/a"
    ]
  },
  {
    "id": 19,
    "titulo": "¿Te gusta ver llover?",
    "opciones": [
      "Sí, en la calle", "Sí, desde casa", "Sí, donde sea", "No",
      "No, lo odio", "Me da igual"
    ]
  },
  {
    "id": 20,
    "titulo": "¿Cuál es tu color preferido?",
    "opciones": [
      "Amarillo Cadmio", "Azul de Prusia", "Tierra de Siena Tostado", "Tierra de Siena",
      "Blanco de España", "Otro"
    ]
  },
  {
    "id": 21,
    "titulo": "¿Sabías que en el año 536 la erupción de un volcán en Islandia sumió a Europa, Oriente Medio y otras partes de Asia en la oscuridad durante 18 meses?",
    "opciones": [
      "Sí", "No", "No, pero se lo voy a contar a mi cuñado ahora mismo",
      "Sí, pero está claro que fue un montaje para acabar con la dinastía justiniana que pretendía restaurar el esplendor imperial en contra de los intereses de la plutocracia globalista",
      "Eso es mentira", "No creo en el año 536"
    ]
  },
  {
    "id": 22,
    "titulo": "¿Cuál es tu canción favorita de Chayanne?",
    "opciones": [
      "Torero", "Enamorado", "Dejaría todo", "Yo te amo", "Atado a tu amor", "Otra"
    ]
  },
  {
    "id": 25,
    "titulo": "¿Sabes que votar es irrelevante y que van a seguir gobernando Mediaset y Atresmedia?",
    "opciones": [
      "Me dejas de piedra", "Sí, y me parece bien porque apoyan la ecología y el feminismo",
      "Eso no es excusa para eludir nuestas obligaciones como ciudadanos y no ir a votar",
      "Sí, yo les voy a votar", "Eso es mentira, lo he visto en Maldito Bulo", "Pues si no te gusta nuestra democracia vete a Venezuela"
    ]
  },
  {
    "id": 24,
    "titulo": "¿Te gustan los botánicos en el gin-tonic?",
    "opciones": [
      "No bebo gin-tonic", "Sí, muchos, así me como una ensalada por el mismo precio",
      "No",
      "Sí, hasta en el Larios", "Sí, pero como mucho dos, no quiero forraje en mi gin-tonic", "No me gustan los botánicos, punto. Odio a Linneo"
    ]
  },
  {
    "id": 23,
    "titulo": "¿Cuando lo usas como adverbio, escribes \"solo\" con o sin tilde?",
    "opciones": [
      "Desde 2010, siempre sin tilde", "Siempre con tilde",
      "A veces con tilde y otras sin tilde",
      "No soy bueno en ortografía pero lo intento", "¿Qué es un adverbio?", "Lo echo a los dados"
    ]
  }
]

export default preguntas;
